
import React, {useState} from "react"
import PropTypes from "prop-types"

import Layout from "../../layout"
import WebappsForm from "../../webapps/form"

const formatTools = require("../../../../lib/formatTools");
const negosyonowAPI = require("../../../../lib/requestNegosyownow")
const webappsAPI = require("../../../../lib/requestWebapps");

const ENTITYID="nnproduct"

const defaultSearchFields = [
	{"label":"Image", "dbfield": "nnproduct_image", "type": "image", "filtertype": "image"},
	{"label":"Seller SKU", "dbfield": "nnproduct_orgsku", "type": "text", "filtertype": "textbox"},
	{"label":"Brand", "dbfield": "nnbrand_name", "type": "text", "filtertype": "textbox"},
	{"label":"Full Name / Display", "dbfield": "nnproduct_fullname", "type": "text", "filtertype": "textbox"},
	{"label":"Active", "dbfield": "nnproduct_active", "type": "checkbox", "filtertype": "checkbox"},
	{"label":"Price", "dbfield": "nnproduct_price", "type": "currency", "filtertype": "currency"},
	{"label":"Last Update", "dbfield": "nnproduct_lastupdate", "type": "datetime", "filtertype": "datetime"},
];

const ProductBasicEditorPage = ({location, allowadd, formFields, createFields, searchFields, customSearchFilter, editSubFormFields, customPostprocessing, customValidateValues}) => {
	const [token, setToken] = useState("");
	const [pagetitle, setPagetitle] = useState("");
	const [docid, setDocid] = useState(-1);
	const [oldalias, setOldalias] = useState(-1);

	function customSubmit(entity, params, payloadlist, subformpayloadlist, token, callback)
	{
		var pkid = 0;
		var aliasid = 0;
		var needsaliasupdate = false;
		//var customparam = {};
		var customparam = JSON.parse(JSON.stringify(params));

		customparam.nnproduct_lastupdate = formatTools.getDateStr();


		if (params.hasOwnProperty("documentid")) {
			pkid = params.documentid;
		}
		if (params.hasOwnProperty("nnproductalias_id")) {
			if (params.nnproductalias_id) {
				aliasid = parseInt(params.nnproductalias_id, 10);
			}
		}

		if (params.hasOwnProperty("nnproductalias_id")) {
			if (pkid > 0) {
				if (aliasid === 0) {
					customparam.nnproductalias_id = pkid;
				}
				needsaliasupdate = true;
			} else {
				// New product, alias set to 0, use auto instead
				if (aliasid === 0 && customparam.hasOwnProperty("nnproductalias_id")) {
					delete customparam.nnproductalias_id;
				}
			}
		}

		//callback({"status":"Error"}); return;

		handleProductSeller(params, token, function(hasbranchid, onsorganization_id) {
			if (hasbranchid) {
				delete params.onsbranch_id;
			}
			if (onsorganization_id > 0) {
				customparam.onsorganization_id = onsorganization_id;
			}
			handleProductAlias(params, token, function(hasalias, aliasdata) {
				if (hasalias) {
					if (aliasdata.nnbrand_id>0) {
						customparam.nnbrand_id = aliasdata.nnbrand_id;
						customparam.nnproduct_name = aliasdata.nnproduct_name;
						customparam.nnproductalias_id = aliasdata.nnproductalias_id;
						customparam.onsorganization_id = aliasdata.onsorganization_id;
					}
				} else if (needsaliasupdate && oldalias > 0) {
					// triggers rebuild on parent product too
					aliasdata.nnproductalias_id = oldalias;
				}
				//console.log("Submit",customparam); console.log("aliasdata",aliasdata); callback({"status":"Error", "message":"Debug"}); return;

				webappsAPI.savePayload(entity, customparam, token, payloadlist, subformpayloadlist, function(response){
					if (response.status === "OK") {
						updatePrimaryImage(response.documentid, token, function(){
							postProductUpdates(entity, token, response.documentid, pkid === 0, aliasdata.nnproductalias_id, params, function() {
								// Check if franchise price is affected
								if (typeof customPostprocessing === "undefined") {
									callback(response);
								} else {
									customPostprocessing(response.documentid, token, function() {
										callback(response);
									});
								}
							});
						});
					} else {
						callback(response);
					}
				});
			});
		});
	}

	function findSubformIdxByTablename(tablename)
	{
		var subformidx = 0;
		while (subformidx < editSubFormFields.length) {
			if (editSubFormFields[subformidx].table === tablename) {
				return subformidx;
			}
			subformidx++;
		}
		return -1;
	}


	function updatePrimaryImage(pkid, token, callback)
	{
		if (findSubformIdxByTablename("nnproductimage")>=0) {
			// TODO, check if has edit/new
			const apiparam = {
				"dbfieldlist":["nnproductimage_id","nnproductimage_url"],
				"orderby":"nnproductimage_primary desc,nnproductimage_id",
				"filters":[[{
						field: "nnproduct_id",
						operation: "=",
						value: pkid
					}]]
			};
			webappsAPI.loadData("nnproductimage", apiparam, token).then(dataresponse => {
				var newmainimage = "";
				if (dataresponse.hasOwnProperty("data")) {
					if (dataresponse.data.length > 0) {
						newmainimage = dataresponse.data[0].nnproductimage_url;
					}
				}
				if (newmainimage.length > 0) {
					webappsAPI.saveRecord("nnproduct", {
							"documentid":pkid,
							"nnproduct_image":newmainimage
						}, token).then(response => {
							callback();
						});
					return;
				}
				callback();
			});
		} else {
			callback();
		}
	}

	function handleProductSeller(params, token, callback)
	{
		const hasbranchparam = params.hasOwnProperty("onsbranch_id");

		if (hasbranchparam) {
			if (params.onsbranch_id) {
				webappsAPI.loadRecord("onsbranch", params.onsbranch_id, token).then(dataresponse => {
					if (dataresponse.hasOwnProperty("fields")) {
						var rowidx = 0;
						var colidx = 0;
						var tmpfield = "";
						while (rowidx < dataresponse.fields.length) {
							colidx = 0;
							while (colidx < dataresponse.fields[rowidx].length) {
								tmpfield = dataresponse.fields[rowidx][colidx].field;
								if (tmpfield === "onsorganization_id") {
									callback(hasbranchparam, dataresponse.fields[rowidx][colidx].value);
									return;
								}
								colidx++;
							} // while col
							rowidx++;
						} // while row
					}
					callback(hasbranchparam, 0);
				});
				return;
			}
		}
		callback(hasbranchparam, 0);
	} // handleProductSeller

	function handleProductAlias(params, token, callback)
	{
		const copyfieldlist = [
			"onsorganization_id", "nnbrand_id", "nnproduct_name"
		];
		var found = false;
		var output = {
			nnproductalias_id: 0,
			onsorganization_id: 0,
			nnbrand_id: 0,
			nnproduct_name: ""
		}

		if (params.hasOwnProperty("nnproductalias_id")) {
			if (params.nnproductalias_id !== null) {
				if (params.nnproductalias_id !== docid && params.nnproductalias_id>0) {
					output.nnproductalias_id = params.nnproductalias_id;
					found = true;
					webappsAPI.loadRecord("nnproduct", params.nnproductalias_id, token).then(dataresponse => {
						if (dataresponse.hasOwnProperty("fields")) {
							var rowidx = 0;
							var colidx = 0;
							var tmpfield = "";
							while (rowidx < dataresponse.fields.length) {
								colidx = 0;
								while (colidx < dataresponse.fields[rowidx].length) {
									tmpfield = dataresponse.fields[rowidx][colidx].field;
									if (copyfieldlist.includes(tmpfield)) {
										output[tmpfield] = dataresponse.fields[rowidx][colidx].value;
									}
									colidx++;
								} // while col
								rowidx++;
							} // while row
							callback(found, output);
							return;
						} // if fields
						callback(found, output);
					});
					return;
				} // has alias
			} // alias not null
		} // has alias property
		callback(found, output);
	} // handleProductAlias

	function postProductUpdates(entity, token, pkid, isnew, nnproductalias_id, params, callback)
	{
		if (isnew) {
			negosyonowAPI.activateEntity(pkid,token, "nnproduct").then(nnactivateresponse => {
				// Activate Entity will set product alias id, trigger build of parent id
				callback();
			});
		} else {
			negosyonowAPI.buildEntityId(pkid,token,"product").then(nnresponse => {
				if (nnproductalias_id === 0 && (params.hasOwnProperty("nnproduct_name") || params.hasOwnProperty("nnbrand_id"))) {
					// Update variant group items
					negosyonowAPI.buildEntityId(pkid,token,"variantgroup").then(nnvariantresponse => {
						callback();
					});
					return;
				} else if (nnproductalias_id > 0 && params.hasOwnProperty("nnproductalias_id")) {
					// Removed from parent, update old variant group items
					negosyonowAPI.buildEntityId(nnproductalias_id,token,"variantgroup").then(nnvariantresponse => {
						callback();
					});
					return;
				}
				callback();
			});
		}
	}

	function customEditFieldInfo(inputlist, idx)
	{
		var tmpfield = {}
		// Add detaching/handling of parent product
		if (inputlist[idx].field === "nnproduct_actualurl") {
			const haspage = parseInt(inputlist[idx-1].value);
			if (haspage === 1) {
				return inputlist[idx];
			}
			tmpfield = JSON.parse(JSON.stringify(inputlist[idx]));
			tmpfield.value = "Not yet available";
			tmpfield.input = "textbox";
			return tmpfield;
		}
		if (inputlist[idx].field === "nnproductalias_fullname" || inputlist[idx].field === "nnbrand_name" || inputlist[idx].field === "nnproduct_name" || inputlist[idx].field === "onsorganization_name") {
			if (inputlist[idx].field === "nnproductalias_fullname") {
				var ispromoitem = false;
				var tmpinputidx = 0;
				while (tmpinputidx < inputlist.length) {
					if ("nnproduct_promoitem" === inputlist[tmpinputidx].field) {
						if (parseInt(inputlist[tmpinputidx].value, 10)>0) {
							ispromoitem = true;
						}
					}
					tmpinputidx++;
				}
				if (ispromoitem === true) {
					tmpfield = JSON.parse(JSON.stringify(inputlist[idx]));
					tmpfield.mode = "required";
					return tmpfield;
				}
			}
			//docid
			var tmporigalias = oldalias;
			var nnproductalias_id=0;
			var listidx = 0;
			while (listidx < inputlist.length) {
				if (inputlist[listidx].field === "nnproductalias_id") {
					nnproductalias_id = parseInt(inputlist[listidx].value, 10);
					if (inputlist[listidx].modified === false) {
						setOldalias(nnproductalias_id);
						tmporigalias = nnproductalias_id;
					}
					break;
				}
				listidx++;
			}

			if (tmporigalias>0 && docid>0) {
				if (inputlist[idx].field === "nnproductalias_fullname") {
					if (nnproductalias_id === docid || nnproductalias_id === 0) {
						tmpfield = JSON.parse(JSON.stringify(inputlist[idx]));
						tmpfield.value = "No Variants/Parent Product";
						return tmpfield;
						/*
					} else {
						tmpfield = JSON.parse(JSON.stringify(inputlist[idx]));
						tmpfield.mode = "readonly";
						return tmpfield;
						*/
					}
				} else if (tmporigalias !== docid) {
					tmpfield = JSON.parse(JSON.stringify(inputlist[idx]));
					tmpfield.mode = "readonly";
					return tmpfield;
				}
			}
		}

		return inputlist[idx];

	}

	function customFieldFragment(inputlist, idx, isdisabled, fieldid, inputchangecallback)
	{
		if (inputlist[idx].field === "nnproduct_aliasreset") {
			var nnproductalias_id=0;
			var listidx = 0;
			while (listidx < inputlist.length) {
				if (inputlist[listidx].field === "nnproductalias_id") {
					nnproductalias_id = parseInt(inputlist[listidx].value, 10);
					break;
				}
				listidx++;
			}

			if (nnproductalias_id>0 && docid>0) {
				if (nnproductalias_id !== docid) {
					// Default View
					return null;
				}
			}
			// Hide by default nnproduct_aliasreset
			return <></>;
		}
		// default
		return null;
	}

	function validateImagePrimaryCheck(imagesubform)
	{
		var primarycount = 0;
		var rowidx = 0;
		var colidx = 0;
		var found = 0;
		while (rowidx < imagesubform.length) {
			colidx = 0;
			while (colidx < imagesubform[rowidx].length) {
				if (imagesubform[rowidx][colidx].field === "nnproductimage_primary") {
					if (imagesubform[rowidx][colidx].value === "1") {
						primarycount++;
					}
					found++;
				}
				colidx++;
			}
			rowidx++;
		}
		if (primarycount !== 1 && rowidx > 0 && found > 0) {
			return {"status":"Error", "message":"Specify 1 main image"}
		}
		return {"status":"OK"};
	}

	function formValidateValues(mainform, subformlist, subformfooterlist)
	{
		var subformidx = findSubformIdxByTablename("nnproductimage");
		if (subformidx>=0) {
			var tmpres = validateImagePrimaryCheck(subformlist[subformidx]);
			if (tmpres.status !== "OK") {
				return tmpres;
			}
		}
		var nnproductalias_id = 0;
		var nnproduct_promoitem = 0;
		subformidx = 0; // reuse variable
		while (subformidx < mainform.length) {
			if (mainform[subformidx].field === "nnproduct_promoitem") {
				nnproduct_promoitem = parseInt(mainform[subformidx].value, 10);
			} else if (mainform[subformidx].field === "nnproductalias_id") {
				nnproductalias_id = parseInt(mainform[subformidx].value, 10);
			}
			subformidx++;
		}

		if ((nnproductalias_id < 1 || nnproductalias_id=== docid) && nnproduct_promoitem > 0) {
			return {"status":"Error", "message":"Parent product is required for promo items"}
		}

		// Already validated for basic checks at this point
		if (typeof customValidateValues !== "undefined") {
			return customValidateValues(mainform, subformlist, subformfooterlist);
		}
		return {"status":"OK"};
	}

	return <Layout location={location}
			newtokenHandler={(newtoken)=>{setToken(newtoken)}}
			newPageTitle={(newtitle)=>{setPagetitle(newtitle)}}
			privatePage={true} usegatedcontent={true}>
			<WebappsForm
				location={location}
				allowadd={allowadd}
				allowdelete={false}
				mobilerowtitlefield={["nnproduct_fullname"]}
				pagetitle={pagetitle}
				userSearchParam={{"orderby":"nnproduct_fullname"}}
				customSetDocumentId={setDocid}
				customSubmit={customSubmit}
				customFieldFragment={customFieldFragment}
				customEditFieldInfo={customEditFieldInfo}
				searchFields={searchFields.length>0?searchFields:defaultSearchFields}
				userSearchFilter={customSearchFilter}
				editFields={formFields}
				newformFields={createFields}
				editSubFormFields={editSubFormFields}
				customValidateValues={formValidateValues}
				entity={ENTITYID}
				token={token} />
		</Layout>

}


ProductBasicEditorPage.propTypes = {
	customSearchFilter: PropTypes.array,
	createFields: PropTypes.array,
	searchFields: PropTypes.array,
	editSubFormFields: PropTypes.array,
	allowadd: PropTypes.bool,
}


ProductBasicEditorPage.defaultProps = {
	customSearchFilter: [],
	createFields: [],
	searchFields: [],
	editSubFormFields: [],
	allowadd: false
}

export default ProductBasicEditorPage;
